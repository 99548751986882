<template>
  <div class="product-carousel__slide">
    <CustomProductCard
      :key="product.uid"
      class="product desktop-product"
      :link="localePath(getSlug(product))"
      :title="getProductTitle(product)"
      :image-alt="getProductImageAlt(product, 'thumbnail')"
      :regular-price="$n(getProductPrice(product).regular, 'currency')"
      :special-price="
        getProductPrice(product).special
          ? $n(getProductPrice(product).special, 'currency')
          : null
      "
      :discount-percentage="calculateDiscountPercentage(product)"
      :product="product"
      :colors="getSfColorsFromAttributes(getProductColorAttribute(product))"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import CustomProductCard from '~/components/CustomProductCard.vue';
import { productGetters } from '@gemini-vsf/composables';
import { useUrlHelpers } from '~/composables';
import {
  getProductColorAttribute,
  getSfColorsFromAttributes,
} from '~/helpers/product/productData';
import { isEmpty } from 'lodash-es';

export default defineComponent({
  name: 'ProductCarouselItem',
  components: {
    CustomProductCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { buildProductUrl } = useUrlHelpers();
    const getProductTitle = (product) => {
      return isEmpty(productGetters.getName(product))
        ? productGetters.getProductSku(product)
        : productGetters.getName(product);
    };

    const calculateDiscountPercentage = (product) => {
      return productGetters.getPrice(product).special
        ? Math.round(
            (1 -
              productGetters.getPrice(product).special /
                productGetters.getPrice(product).regular) *
              100
          )
        : null;
    };

    return {
      buildProductUrl,
      getRolloverImage: productGetters.getRolloverImage,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName: productGetters.getName,
      getProductPrice: productGetters.getPrice,
      getProductImageAlt: productGetters.getProductImageAlt,
      getSlug: productGetters.getSlug,
      getProductColorAttribute,
      getSfColorsFromAttributes,
      calculateDiscountPercentage,
      getProductTitle,
    };
  },
});
</script>

<style lang="scss">
.product-carousel {
  &__slide {
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: linear-gradient(180deg, #242428, #242428);
    @include border-radius-standard;
    box-shadow: 0 0.1875rem 1.25rem 0 rgba(0, 0, 0, 0.32);
  }
}
</style>
