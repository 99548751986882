<template>
  <div class="product-detail__carousel product-carousel">
    <div class="product-carousel__title" v-if="title">
      {{ $t(title) }}
    </div>
    <div
      class="glide-product-carousel product-carousel__glide"
      :ref="`glide-${date}`"
      :id="`glide-${date}`"
    >
      <div class="glide__track" data-glide-el="track">
        <div class="glide__slides">
          <div
            class="glide__slide"
            v-for="(product, index) in products"
            :key="`${product.grn}-${index}`"
          >
            <ProductCarouselItem
              class="carousel-product-card"
              :product="product"
              v-if="product"
            />
          </div>
        </div>
      </div>
      <div class="glide-product-carousel__arrows" data-glide-el="controls">
        <button
          class="glide-product-carousel__arrows__arrow--left glide__arrows__arrow glide__arrow glide__arrow--left"
          data-glide-dir="<"
        >
          <nuxt-img src="/header/right-arrow.svg" />
        </button>
        <button
          class="glide-product-carousel__arrows__arrow--right glide__arrows__arrow glide__arrow glide__arrow--right"
          data-glide-dir=">"
        >
          <nuxt-img src="/header/right-arrow.svg" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import Glide, {
  Breakpoints,
  Controls,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import { useWishlist } from '~/composables';
import { productGetters } from '@gemini-vsf/composables';
import ProductCarouselItem from '~/components/General/ProductCarouselItem.vue';

export default defineComponent({
  name: 'ProductCarousel',
  components: {
    ProductCarouselItem,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'You might also be interested in',
    },
  },
  setup(props) {
    const { loadItemsInWishlist } = useWishlist();
    const glideInstance = ref();
    const { getSlug } = productGetters;
    const date = Date.now();
    const loadGlide = () => {
      const glideConfiguration = {
        type: 'carousel',
        perView: 4,
        peek: {
          before: 0,
          after: 250,
        },
        gap: 10,
        breakpoints: {
          767: {
            perView: 1,
            peek: {
              before: 0,
              after: 140,
            },
          },
          1025: {
            perView: 3,
            peek: {
              before: 0,
              after: 140,
            },
          },
        },
      };
      glideInstance.value = new Glide(
        `#glide-${date}`,
        glideConfiguration
      ).mount({
        Breakpoints,
        Controls,
        Swipe,
      });
    };

    onMounted(async () => {
      loadGlide();
      const productSkus = props.products.map((product) => product.sku);
      await loadItemsInWishlist(productSkus);
    });

    return {
      getSlug,
      date,
    };
  },
});
</script>

<style lang="scss">
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';
.product-carousel {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 2.5625rem;
  padding: 2.5rem 0 3.125rem 0;
  .glide-product-carousel {
    padding-left: 0.9375rem;
    &__slide {
      height: auto;
    }
    &__arrows {
      display: none;
    }
    .carousel-product-card {
      .desktop-product {
        .sf-product-card__title {
          @include product-card-text-ellipsis;
          @include ri-from-tablet {
            height: 3.125rem;
          }
          @include ri-to-tablet {
            height: 2rem;
          }
        }
      }
    }
  }
  &__title {
    font-family: var(--ri-font-family);
    font-size: 1.875rem;
    letter-spacing: 0;
    line-height: 2.1875rem;
    font-weight: bold;
    @include flex-center;
    padding: 0 1rem;
    text-align: center;
  }
}
@include from-desktop-min {
  .product-carousel {
    padding: 3.25rem 0;
    position: relative;
    .glide-product-carousel {
      &__arrows {
        display: block;
        &__arrow {
          &--left {
            left: -3.5rem;
            transform: rotate(180deg);
            box-shadow: 0 -0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
          }
          &--right {
            right: -3.5rem;
          }
          &--left,
          &--right {
            border: none;
          }
        }
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 25rem;
      height: 100%;
      z-index: 1;
    }
    &:before {
      left: -25rem;
    }
    &:after {
      right: -25rem;
    }
  }
}
</style>
